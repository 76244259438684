import { defineNuxtRouteMiddleware, useRouter } from '#app'
import * as Sentry from '@sentry/vue'

export default defineNuxtRouteMiddleware((to) => {
  const router = useRouter()

  router.onError((error: any) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      Sentry.captureException(new Error('Dynamic Import Failed'), {
        extra: {
          url: to.fullPath,
          errorDetails: JSON.stringify(error),
        },
        tags: {
          errorType: 'DYNAMIC_IMPORT_FAILED',
        },
      })
    }
  })
})
